import React from "react";
import AboutUsSummary from "../components/AboutUsSummary";

const AboutUs = () => {
    return (
       <>
       <AboutUsSummary />
       </>
    );
}

export default AboutUs;
import RegistryCards from "../components/RegistryCards";
import RegistryHero from "../components/RegistryHero";

const Registry = () => {
    return (
        <>
            <RegistryHero />
        <RegistryCards />
        </>
    );
}

export default Registry;
import React from 'react';
import GalleryCard from "../components/GalleryCard";

const Gallery = () => {
    return (
        <>
        <GalleryCard />
        </>
    );
}

export default Gallery;
import React from 'react';
import ContactUs from "../components/ContactUs";

const Contact = () => {
    return (
       <>
       <ContactUs />
       </>
    );
}

export default Contact;
import React from 'react';
import HotelsCard from "../components/HotelsCard";

const Hotels = () => {
    return (
        <>
            <HotelsCard />
        </>
    );
}

export default Hotels;